const newsCategoryArr = [
  '院內公告',
  '熱門消息',
  '醫美專欄',
  // '媒體相關',
  '影音專區',
];

let serviceCategoryArr = [
  {
    name: '美容保養',
    description: `透過醫學美容專業護膚課程，達到內外兼具的美麗。
改善：青春痘，粉刺，膚況不穩，體內代謝，乾燥暗沉等肌膚問題`,
    img: '../../images/service/美容保養1.jpg',
    img_small: '../../images/service/美容保養s.jpg',
    path: '/services?cat=美容保養',
  },
  {
    name: '微整注射',
    description: `透過填充劑注射的方式，修飾臉型五官，提升臉部精緻度及立體度。
改善：臉型線條及飽滿度修飾，動態紋路，靜態紋路，五官立體度`,
    img: '../../images/service/微整注射1.jpg',
    img_small: '../../images/service/微整注射s.jpg',
    path: '/services?cat=微整注射',
  },
  {
    name: '拉提美學',
    description: `透過埋線，電音波等課程，對抗地心引力，喚回肌齡，重現年輕緊緻。
改善：輪廓線模糊，膚質鬆垮，老化下垂`,
    img: '../../images/service/拉提美學1.jpg',
    img_small: '../../images/service/拉提美學s.jpg',
    path: '/services?cat=拉提美學',
  },
  {
    name: '雷射光療',
    description: `透過不同波長的「光」達到膚質改善及美容保養的效果。
改善：毛孔粗大，斑點色素痘疤，膚色不均，凹洞疤痕，除毛`,
    img: '../../images/service/雷射光療1.jpg',
    img_small: '../../images/service/雷射光療s.jpg',
    path: '/services?cat=雷射光療',
  },
  {
    name: '女性私密',
    description: `女人最珍貴的私密肌膚，需要從裡到外的用心呵護，恢復自在清爽
改善：老化乾澀，產後鬆弛漏尿，反覆感染發炎，私密處暗沉 `,
    img: '../../images/service/女性私密1.jpg',
    img_small: '../../images/service/女性私密s.jpg',
    path: '/services?cat=女性私密',
  },
  {
    name: '整形手術',
    description: `透過外科手術針對個人眼型條件及需求改變外觀，眼睛有神更帶電
改善：內雙，單眼皮，大小眼，眼袋，淚溝`,
    img: '../../images/service/整形手術1.jpg',
    img_small: '../../images/service/整形手術s.jpg',
    path: '/services?cat=整形手術',
  },
];

export {serviceCategoryArr, newsCategoryArr};
